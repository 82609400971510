import React, { useState, useEffect } from "react";
import { navigate } from "gatsby-link";
import { Container, Typography, Button } from "../../components/utils";
import Layout from "../../components/layout";
import { Input } from "../../components/forms";
import Modal from "../../components/modal";
import SpotlioButton from "../../components/button";
import axios from "axios";
import Checkbox from "../../components/checkbox";
import ProductList from "../../components/checkout/productList";
import Icon from "../../components/icons";
import Link from "../../components/link";
import { CONNECT, CONNECT_CLIENT } from "../../utils/definitions";
import {
  getSession,
  setSession,
  getOldSession,
  setOldSession,
  setLocale,
  getLocale,
} from "../../utils/localStorage";
import { LocaleAsString } from "../../localeEngine";
import { gtmFunction } from "../../utils/gtm";
import QueryStringRetriever from "../../utils/queryStringRetriever";
import * as styles from "./styles.module.scss";

const Box = ({ children, grid, className }) => {
  let classes = `${styles.box}`;

  if (grid) classes += ` ${styles.grid}`;

  if (className) classes += ` ${className}`;

  return <div className={classes}>{children}</div>;
};

const Form = () => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);

  const updateForm = (name, event) => {
    const currentForm = { ...form };
    currentForm[name] = event.target.value;
    setForm(currentForm);
  };

  return (
    <div className={styles.form}>
      <Typography variant="h700-medium" component="h3">
        Add passholder
      </Typography>
      <Input
        label="Full name"
        icon="User"
        required
        name="fullName"
        value={form.fullName}
        type="text"
        onChange={(event) => updateForm("fullName", event)}
      />
      <Input
        label="Date of birth"
        icon="Calendar"
        required
        name="birth"
        value={form.birth}
        type="date"
        onChange={(event) => updateForm("birth", event)}
      />
      <SpotlioButton
        className={styles.buttonSave}
        config={{
          skin: "spotlio2",
          label: "Save and continue",
          backgroundColor: "#2fac00",
          borderColor: "",
          foregroundColor: "#ffffff",
        }}
        loading={loading}
        onClick={() => setLoading(true)}
      />
    </div>
  );
};

const Completed = () => {
  const [modal, setModal] = useState(false);
  const [password, setPassword] = useState("");
  const [stateSession, setStateSession] = useState(false);

  useEffect(() => {
    let session = getSession();
    let oldSession = getOldSession();
    let { /*preview,*/ gtm = true } = QueryStringRetriever();
    // we check if the user is refreshing (oldSession is a completed one)
    // or if it's the first time in completed screen (session is a completed one)
    // if neither of them is, redirect the user to the checkout

    // if (
    //   parseInt(preview) !== 1234 &&
    //   oldSession?.status !== "completed" &&
    //   session?.status !== "completed"
    // ) {
    //   navigate(`/checkout`);
    // } else {

    // if the current session is the completed one, we do the "clean process and save it in case of refresh"
    if (session.products?.length > 0 /*&& session.status === "completed"*/) {
      setLocale(getLocale());
      setStateSession(session);
      setOldSession(session);
      axios
        .post(`${CONNECT}/checkout/session/new`, {
          client: CONNECT_CLIENT,
          session: getSession().uuid,
        })
        .then((r) => {
          if (gtm) gtmFunction("purchase", session);
          setSession(r.data);
        });
    } else {
      // if not, then we use the completed oldSession
      setStateSession(oldSession);
    }
    // }
  }, []);

  let showGoToMyAccount = false;
  if (stateSession && stateSession.products) {
    if (Array.isArray(stateSession.products)) {
      if (
        stateSession.products.filter((prod) => prod.fulfilled !== true).length >
        0
      ) {
        showGoToMyAccount = true;
      }
    }
  }

  const goToMyAccount = () => {
    if (typeof window !== "undefined") {
      navigate(`/my-account?orderId=${stateSession.id}`);
    }
  };

  if (!stateSession) return null;
  return (
    <Layout
      metatags={{ title: LocaleAsString({ id: "checkout.title" }) }}
      hideMobileFooter={true}
      backgroundColor={"#f4f4f9"}
    >
      <Container
        maxWidth={"lg"}
        component={"section"}
        className={styles.container}
      >
        <Typography
          variant="h900-medium"
          component="p"
          className={styles.title}
        >
          <LocaleAsString id="checkout.title" />
        </Typography>
        <Box grid className={styles.confirmation}>
          <Icon
            icon="CheckCircle"
            size="Small"
            color={"#2FAC00"}
            className={styles.icon}
          />
          <Typography
            component="p"
            variant="h500-heavy"
            className={styles.text}
          >
            <LocaleAsString id="checkout.confirmation.title" />
          </Typography>
          <Typography
            component="p"
            variant="h400-medium"
            className={styles.desc}
          >
            <LocaleAsString id="checkout.confirmation.description_one" />
            <br />
            <br />
            <LocaleAsString id="checkout.confirmation.description_two" />{" "}
            <Link
              url={`/my-account?section=orders${
                stateSession.id ? `&orderId=${stateSession.id}` : ""
              }`}
              target="_blank"
              className={styles.hyperlink}
            >
              <LocaleAsString id="checkout.link" />
            </Link>
          </Typography>
          <div className={styles.warning}>
            <Icon icon="AlertCircle" size="Small" />
            <Typography component="p" variant="h300-medium">
              <LocaleAsString id="checkout.info_message" />
            </Typography>
          </div>
          {showGoToMyAccount && (
            <>
              <div className={styles.error}>
                <Icon icon="AlertTriangle" size="Small" />
                <Typography variant="h300-medium" component="span">
                  <LocaleAsString id="checkout.completed.assign_passholders" />
                </Typography>
              </div>
              <Button
                onClick={() => goToMyAccount()}
                className={styles.CTA}
                contentClassName={styles.CTAContent}
              >
                <Typography
                  variant="h400-medium"
                  component="span"
                  className={styles.label}
                >
                  <LocaleAsString id="checkout.completed.assign_passholders_cta" />
                </Typography>
                <Icon icon="ArrowRight" size="S" />
              </Button>
            </>
          )}
        </Box>

        <div className={styles.products}>
          <Box grid>
            <div className={styles.summary}>
              <Typography variant="h500-medium" component="span">
                <LocaleAsString id="cart.products.shopping_cart" />
              </Typography>
              <Typography variant="h400-medium" component="span">
                <LocaleAsString id="my_account.menu.order" /> #{stateSession.id}
              </Typography>
            </div>
          </Box>
          <Box grid>
            <ProductList products={stateSession.products} isCompleted={true} />
          </Box>
        </div>

        <div style={{ display: "none" }}>
          <Box grid>
            <Icon icon="GuestCard" color={"#3595BA"} className={styles.icon} />
            <Typography component="p" className={styles.title}>
              Brukere / kortinnehavere
            </Typography>
            <Typography component="p" className={styles.description}>
              For å aktivere dine sesongkort, så må du legge til nødvendig
              personlig informasjon for hver av brukerne (inntil 2 voksne og
              inntil 4 junior fra samme husholdning)
            </Typography>
            <div
              className={styles.button}
              onClick={() => setModal(true)}
              onKeyDown={() => setModal(true)}
              role="button"
              tabIndex={0}
            >
              <Typography component={"p"} className={styles.label}>
                Legg til bruker
              </Typography>
              <div className={styles.floatIcon}>
                <Icon icon="Plus" color={"#ffffff"} />
              </div>
            </div>
          </Box>
        </div>
        <div style={{ display: "none" }}>
          <Box grid>
            <Icon icon="User" color={"#3595BA"} className={styles.icon} />
            <Typography component={"p"} className={styles.insider}>
              Legg inn et selvvalgt passord under, så kan du logge deg inn
              lynkjapt neste gang.
            </Typography>
            <Input
              label="Passord"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className={styles.privacyStatement}>
              <Checkbox />
              <div className={styles.privacyClaim}>
                <Typography
                  className={styles.smooth}
                  component="span"
                  variant="h300-medium"
                >
                  Jeg har lest og akseptert vår
                </Typography>
                <Typography component="span" variant="h300-medium">
                  Personvernerklæring
                </Typography>
              </div>
            </div>
          </Box>
        </div>
      </Container>
      {modal && (
        <Modal
          closeFn={() => setModal(false)}
          borderRadius={8}
          size="small"
          isAlert={false}
        >
          <Form />
        </Modal>
      )}
    </Layout>
  );
};

export default Completed;
